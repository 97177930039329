/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

jQuery(document).ready(function ($) {
    $(".clickable-row").click(function () {
        window.location = $(this).data("href");
    });
    
    $("form.confirm").on("submit", function(){
        return confirm("Sind Sie sicher?");
    });

    $(".form-block-trigger").click(function(n) {
        n.preventDefault;
        $(this).toggleClass('triggered');
        $($(this).data('target')).toggleClass('triggered').fadeToggle();
    })

    $(".form-block.collapsed").each(function(){
        if ($(this).find('input').val().length !== 0) {
            $(this).addClass('triggered').show();
            $('*[data-target="#'+$(this).attr('id')+'"]').addClass('triggered');
        }
    })
});
